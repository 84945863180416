import React from "react";
import ReactDOM from "react-dom/client";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import "core-js/features/promise";
import "intro.js/introjs.css";
import "react-activity/dist/library.css";
import "@src/styles/application.scss";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { FallbackPage } from "@src/pages/fallback";

declare global {
  interface BigInt {
    toJSON(): string;
  }
}

BigInt.prototype.toJSON = function () {
  return this.toString();
};

Sentry.init({
  dsn: "https://f61f7f46bd9a12341c3cb145da646109@o4507904307363840.ingest.de.sentry.io/4507904318701648",
  enabled: process.env.NODE_ENV === "production",
  environment: import.meta.env.VITE_APP_SENTRY_ENV || "dev",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<FallbackPage />}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
